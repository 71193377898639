<template>
  <div class="content">
    <a-icon
      class="icons"
      type="check-circle"
      theme="twoTone"
      two-tone-color="#52c41a"
    />
    <p v-if="type === 1">注册成功</p>
    <p v-else>密码修改成功</p>
    <div class="btns more">
      <a-button :size="size" @click="HomeRoute">再逛逛</a-button>
      <a-button :size="size" type="primary" @click="loginRoute"
        >去登录</a-button
      >
    </div>
    <p class="authentication">为了便于您了解选品下单，请及时进行企业认证。</p>
    <!-- <div class="btns" v-else>
      <a-button :size="size" @click="HomeRoute">返回首页</a-button>
    </div> -->
  </div>
</template>
<script>
import Routerpush from "@/mixins/Routerpush";
export default {
  mixins: [Routerpush],
  data() {
    return {
      size: "large",
      type: 3, //注册成功 1,密码修改成功(未登录)2, 密码修改成功（登录） 3
    };
  },

  mounted() {
    this.type = +this.$route.query.type;
    console.log(this.type);
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 250px;
  margin: 0 auto;
  margin-top: 15%;
  position: relative;
  z-index: 50;
  text-align: center;
  p {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
  }
}
.icons {
  font-size: 60px;
}
.btns {
  text-align: center;
  & > * {
    display: inline-block;
  }
}
.authentication {
  font-size: 16px !important;
}
.more {
  & > * {
    &:last-child {
      margin-left: 10px;
    }
  }
}
</style>
